<template>
  <div>
        <VueSlickCarousel :arrows="false" :dots="false" v-bind="settings">
            <div v-for="(item,index) in banner" :key="index">
                <!-- <a :href="item.link" :target="item.link===''?'_self':'_blank'"><img :src="item.src" alt="banner"  class="img-fluid"></a> -->
                <a :href="item.link" :target="item.type==='self'?'_self':'_blank'"><img :src="item.src" alt="banner"  class="img-fluid"></a>
            </div>
        </VueSlickCarousel>
        <!-- <div class="banner position-relative p-3">
            <img src="@/assets/img/croc.svg" alt="croco" class="position-absolute croco-icon zindex-1">
            <div class="text-white text-end position-relative zindex-2 h-100">
                <p class="fs-px-13">1COINBUY is going to be launched in JUNE 13th</p>
                <h2 class="fw-bold">You can be a seller!</h2>
                <a href="http://naver.com" class="arrow-box" target="_blank">
                    READ MORE
                    <div class="arrow"></div>
                </a>
            </div>
        </div> -->
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'MyComponent',
    components: { VueSlickCarousel },
    data(){
        return{
            banner:[
                {
                    src:require('@/assets/img/banner/b_banner_1.jpg'),
                    link:'/notice/697d3a0e-06e2-4b3f-a122-c78664d94a27',
                    type:'self'
                },
                {
                    src:require('@/assets/img/banner/b_banner_2.jpg'),
                    link:'/notice/0ffe5637-1e75-4016-bf41-a6579fe07530',
                    type:'self'
                },
                // {
                //     src:require('@/assets/img/banner/banner5.jpg'),
                //     link:'https://onecoinbuy.com/serviceguideEn'
                // }
            ],
            settings:{
                "autoplay": true,
                "speed": 500,
                "autoplaySpeed": 3000,
                
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.banner{
    background-color: #65274A;
    height: 150px;
}
.croco-icon{
    height: 100px;
    left: 20px;
    bottom: 12px;
}
.arrow-box{
    position: absolute;
    letter-spacing: 1px;
    padding-right: 10px;
    right: 15px;
    bottom: 15px;
    font-size: 13px;
    border-bottom: 2px solid #FFB800;
    .arrow{
        position: absolute;
        bottom: -5px;
        right: 0;
        border: solid #FFB800;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
    }
}
</style>